import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';

import { loader } from './load-script';
import {
  RECAPTCHA_BASE_URL,
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_LOADER_OPTIONS,
  RECAPTCHA_NONCE,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaLoaderOptions,
} from './tokens';

function toNonNullObservable<T>(subject: BehaviorSubject<T>): Observable<T> {
  return subject.asObservable().pipe(filter<T>(value => value !== null));
}

@Injectable({ providedIn: 'root' })
export class RecaptchaLoaderService {
  /**
   * @internal
   * @nocollapse
   */
  private static ready: BehaviorSubject<ReCaptchaV2.ReCaptcha | null> | null = null;
  public ready: Observable<ReCaptchaV2.ReCaptcha>;
  private readonly platformId = inject<Object>(PLATFORM_ID);
  /** @internal */
  private readonly language?: string | null;
  /** @internal */
  private readonly baseUrl?: string | null;
  /** @internal */
  private readonly nonce?: string | null;
  /** @internal */
  private readonly v3SiteKey?: string | null;
  /** @internal */
  private options?: RecaptchaLoaderOptions | null;

  constructor() {
    this.options = inject<RecaptchaLoaderOptions>(RECAPTCHA_LOADER_OPTIONS, { optional: true });
    this.v3SiteKey = inject<string>(RECAPTCHA_V3_SITE_KEY, { optional: true });
    this.nonce = inject<string>(RECAPTCHA_NONCE, { optional: true });
    this.baseUrl = inject<string>(RECAPTCHA_BASE_URL, { optional: true });
    this.language = inject<string>(RECAPTCHA_LANGUAGE, { optional: true });

    const subject = this.init();

    if (subject) {
      const observable = toNonNullObservable(subject);

      if (observable) {
        this.ready = <Observable<ReCaptchaV2.ReCaptcha>>observable;
      } else {
        this.ready = of();
      }
    } else {
      this.ready = of();
    }
  }

  public destroy(): void {
    loader.removeScript();
  }

  /** @internal */
  private init(): BehaviorSubject<ReCaptchaV2.ReCaptcha | null> | undefined {
    if (RecaptchaLoaderService.ready) {
      return RecaptchaLoaderService.ready;
    }

    if (!isPlatformBrowser(this.platformId)) {
      return undefined;
    }

    const subject = new BehaviorSubject<ReCaptchaV2.ReCaptcha | null>(null);
    RecaptchaLoaderService.ready = subject;

    loader.newLoadScript({
      v3SiteKey: this.v3SiteKey,
      onBeforeLoad: url => {
        if (this.options?.onBeforeLoad) {
          return this.options.onBeforeLoad(url);
        }

        const newUrl = new URL(this.baseUrl ?? url);

        if (this.language) {
          newUrl.searchParams.set('hl', this.language);
        }

        return {
          url: newUrl,
          nonce: this.nonce,
        };
      },
      onLoaded: recaptcha => {
        let value = recaptcha;
        if (this.options?.onLoaded) {
          value = this.options.onLoaded(recaptcha);
        }

        subject.next(value);
      },
    });

    return subject;
  }
}
