export const environment = {
  production: true,

  httpPort: 5555,
  wsPort: 5556,

  baseUrl: 'https://archery-events.com',

  recaptchaMinScore: 0.7,
  recaptchaElevatedMinScore: 0.9,
  recaptchaV2: '6Ldj_b8pAAAAAKFhqHxAQJ5BqR2RkrdRa2dXtF9Q',
  recaptchaV3: '6LdX_b8pAAAAAIrBSXeMZ79TLFHDLg-XUXF5gFoH',

  googleMaps: 'AIzaSyB5MZAGkKKptfE0xDSt7XLzlYPtWwxVxbI',
};
