import { RecaptchaLoaderOptions } from './tokens';

declare global {
  interface Window {
    archeryScoringRecaptchaLoaded?(): void;
  }
}

export type RenderMode = 'explicit' | { key: string };

function removeScript() {
  const scripts = document.body.getElementsByTagName('script');

  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src.startsWith('https://www.google.com/recaptcha/api.js')) {
      document.body.removeChild(scripts[i]);
      break;
    }
  }
}

function loadScript(
  renderMode: RenderMode,
  onBeforeLoad: (url: URL) => { url: URL; nonce?: string | null | undefined },
  onLoaded: (grecaptcha: ReCaptchaV2.ReCaptcha) => void,
  { url, lang, nonce }: { url?: string; lang?: string; nonce?: string } = {}
): void {
  window.requestIdleCallback(() => {
    window.archeryScoringRecaptchaLoaded = () => {
      // @ts-ignore
      onLoaded(grecaptcha);
    };

    const script = document.createElement('script');
    script.type = 'text/javascript';

    const { url: baseUrl, nonce: onBeforeLoadNonce } = onBeforeLoad(
      new URL(url || 'https://www.google.com/recaptcha/api.js')
    );
    baseUrl.searchParams.set('render', renderMode === 'explicit' ? renderMode : renderMode.key);
    baseUrl.searchParams.set('onload', 'archeryScoringRecaptchaLoaded');
    baseUrl.searchParams.set('trustedtypes', 'true');
    if (lang) {
      baseUrl.searchParams.set('hl', lang);
    }

    script.src = baseUrl.href;

    const nonceValue = onBeforeLoadNonce || nonce;

    if (nonceValue) {
      script.setAttribute('nonce', nonceValue);
    }
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  });
}

function newLoadScript({
  v3SiteKey,
  onBeforeLoad,
  onLoaded,
}: {
  v3SiteKey: string | null | undefined;
  onLoaded(recaptcha: ReCaptchaV2.ReCaptcha): void;
} & Pick<Required<RecaptchaLoaderOptions>, 'onBeforeLoad'>) {
  const renderMode: RenderMode = v3SiteKey ? { key: v3SiteKey } : 'explicit';

  loader.loadScript(renderMode, onBeforeLoad, onLoaded);
}

export const loader = { loadScript, newLoadScript, removeScript };
